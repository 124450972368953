import React from 'react';


const VideoSection = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between   bg-gradient-to-r from-[#e9efdb] to-white px-[14%] lg:pt-[210px] mm:pt-[105px]">
      <div className="md:w-[35%] text-center md:text-left mb-6 md:mb-0  flex items-center justify-center
       w-auto">
        <div className=' w-fit'>
        <div className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#FFC510]  to-[#608A06]">
        Veja o que 
      </div>
            <div className="text-xl md:text-2xl text-gray-700 font-Sora leading-none pb-1">espera por você!</div>
            <div className='uppercase font-Sora text-sm'>aperte o play</div>
        </div>
      </div>
      <div className="md:w-[65%]">
        <video className="w-full h-auto rounded-2xl" controls>
          <source src="" type="video/mp4" />
          
        </video>
      </div>
    </div>
  );
};

export default VideoSection;
